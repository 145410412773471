<template>
  <div>
    <v-data-table
      :footer-props="{
        'items-per-page-text': $vuetify.breakpoint.smAndDown
          ? ''
          : $t('items_per_page'),
      }"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    >
      <!-- Me funciona bien pero creandolo como una card, tengo que mirarlo bien -->
      <!-- <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" large
                >mdi-dots-horizontal</v-icon
              >
            </template>
            <v-list>
              <v-list-item @click="edit(item)">
                <v-list-item-title>
                  <span>{{
                    $t("edit", { name: $tc("movement") })
                  }}</span></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template> -->
      <template v-slot:item.data="{ item }">
        {{ $d(new Date(item.data), "dateShort") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon small class="mx-1" @click="edit(item)">
          <v-icon size="16">$edit</v-icon>
        </v-btn>
        <v-btn icon small class="mx-1" @click="del(item)">
          <v-icon size="16">$delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog1" width="400">
      <v-card>
        <v-card-title>{{ $t("edit", { name: $tc("movement") }) }}</v-card-title>
        <div class="close">
          <v-btn icon @click="dialog1 = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <inventorymovements-form
          ref="formmove"
          @cancel="dialog1 = false"
          :inventorymovement="inventorymovements"
          :action="action"
          title="view"
          :productId="$route.params.id"
          @refreshTable="dialog1 = false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import InventorymovementsForm from "@/components/inventorymovement/Form";
export default {
  name: "InventorymovementsTable",
  props: {
    type: {},
    title: {},
    size: {
      default: "big",
    },
    height: {
      default: "200px",
    },
    outlined: {},
    underline: { default: false },
  },
  components: { InventorymovementsForm },

  watch: {
    options: {
      handler() {
        this.fetchInventorymovements();
      },
      deep: true,
    },
  },
  data() {
    return {
      today: null,
      items: [],
      totalInventorymovements: 0,
      options: {},
      overlay: true,
      total: 0,
      filters: {},
      action: "save",
      expense: { recurrente: false }, //borrar
      showAddButton: true,
      dialog1: false,
      inventorymovements: {},
    };
  },
  mounted() {
    this.filters.product_id = this.$route.params.id;
  },
  methods: {
    ...mapActions("inventorymovements", [
      "getInventorymovements",
      "delInventorymovements",
      "getTotalAmount",
      "confirmInventorymovements",
      "getAll",
    ]),
    fetchInventorymovements() {
      this.getInventorymovements({
        paginate: this.options,
        filters: this.filters,
      }).then((inventorymovements) => {
        this.items = inventorymovements.data;
        this.totalInventorymovements = inventorymovements.total;
        this.action = "save";
        this.$emit("fetch", this.items);
      });
    },
    edit(inventorymovements) {
      this.action = "edit";
      console.log(inventorymovements);
      this.inventorymovements = inventorymovements;
      if (
        this.inventorymovements.type_product == "materia prima" ||
        this.inventorymovements.type_product == "mercaderia"
      ) {
        setTimeout(() => {
          this.$refs.formmove.changeType(0);
        }, 500);
      } else if (
        this.inventorymovements.type_product == "venta" ||
        this.inventorymovements.type_product == "consumo"
      ) {
        setTimeout(() => {
          this.$refs.formmove.changeType(1);
        }, 500);
      }

      this.dialog1 = true;
    },
    del(inventorymovements) {
      this.$confirm(
        this.$t("delete_inventorymovements_info", {
          type: this.title,
        }),
        "",
        ""
      ).then(() => {
        this.delInventorymovements(inventorymovements.id).then(() => {
          this.$alert(
            "",
            this.$t("delete_inventorymovements_confirm", {
              type: this.title,
            }),
            ""
          );
          this.fetchInventorymovements();
          this.$emit("delete");
        });
      });
    },
    openForm() {
      this.$refs.form.open();
    },
    actionSave() {
      this.action = "save";
    },

    confirm(id) {
      this.confirmExpense(id).then(() => {
        this.$emit("update");
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Fecha"),
          align: "center",
          sortable: false,
          value: "data",
        },
        {
          text: this.$t("Cantidad"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
};
</script>
<style lang="sass">
#tableLauncher

#card
  .appoinment_penging
    font-weight: bolder

@media (max-width: 400px)
  #title
    height: 50px !important
  #number
    font-size: 40px !important
  .v-card__title
    padding-top: 0px
</style>