var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"footer-props":{
      'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
        ? ''
        : _vm.$t('items_per_page'),
    },"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.data), "dateShort"))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$edit")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.del(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$delete")])],1)]}}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("edit", { name: _vm.$tc("movement") })))]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog1 = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('inventorymovements-form',{ref:"formmove",attrs:{"inventorymovement":_vm.inventorymovements,"action":_vm.action,"title":"view","productId":_vm.$route.params.id},on:{"cancel":function($event){_vm.dialog1 = false},"refreshTable":function($event){_vm.dialog1 = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }